<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'courses' }"
          >Course</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Update Course
      </div>
      <v-form @submit.prevent="submit" ref="form">
        <div class="row">
          <div class="col-md-5">
            <v-divider class="pb-5"></v-divider>
            <label class="text-field-label">Course Category</label>
            <v-select
              flat
              solo
              class="mt-2"
              aspect-ratio="1"
              :items="courseCategories"
              item-text="label"
              item-value="id"
              placeholder="Select Course Category"
              v-model="form.category"
              :error-messages="form.$getError('category')"
            ></v-select>

            <label class="text-field-label">Title</label>
            <v-text-field
              flat
              solo
              required
              counter
              class="mt-2"
              :rules="rules.title"
              maxlength="255"
              v-model="form.title"
              :error-messages="form.$getError('title')"
            ></v-text-field>

            <label class="text-field-label">Description</label>
            <v-textarea
              solo
              maxlength="255"
              name="input-7-4"
              v-model="form.description"
              placeholder="Description"
              :error-messages="form.$getError('description')"
            ></v-textarea>

            <label class="text-field-label">Duration</label>
            <v-text-field
              flat
              solo
              required
              type="number"
              counter
              max="60"
              oninput="if(this.value < 0) this.value = 0;"
              placeholder="Days"
              v-model="form.duration"
              :error-messages="form.$getError('duration')"
            ></v-text-field>

            <label class="text-field-label">Product ID</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.product_id"
              :error-messages="form.$getError('product_id')"
            ></v-text-field>

            <v-row no-gutters>
              <v-col>
                <label class="text-field-label"
                  >For Subscribe User Purchase Only</label
                >
                <v-switch v-model="form.subscriber_only" inset></v-switch>
              </v-col>
              <v-col>
                <label class="text-field-label">Free Course</label>
                <v-switch v-model="form.is_free" inset></v-switch>
              </v-col>
            </v-row>

            <v-divider class="my-5"></v-divider>
            <div class="mt-4">
              <v-btn
                color="primary"
                class="mr-4 px-6"
                height="40px"
                width="70%"
                @click="submit"
                :loading="form.$busy"
                ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
              >
            </div>
            <div class="mt-4">
              <v-btn
                @click="showDeleteModal = true"
                height="40px"
                width="70%"
                color="error"
                ><v-icon left dark> {{ icons.remove }} </v-icon>Delete Course
              </v-btn>
            </div>
          </div>
          <div class="col" />
          <div class="col-md-6">
            <label class="text-field-label">Enable</label>
            <v-switch v-model="form.status" inset></v-switch>

            <label class="text-field-label">Photo</label>
            <br />
            <br />
            <input v-model="form.photo" hidden />
            <div class="col-md-6">
              <v-btn
                @click="showCourseUpload = true"
                color="secondary"
                class="mb-2 ml-3"
              >
                {{ photoCover ? 'Change' : 'Add' }} Photo
              </v-btn>
              <div class="col-md-9">
                <v-img
                  size="100%"
                  class="mb-4"
                  v-if="photoCover"
                  :src="photoCover"
                  :lazy-src="photoCover"
                ></v-img>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="activityDetails"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2 pull-right"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Add Activity Course
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <label class="text-field-label">Title</label>
                            <v-text-field
                              flat
                              solo
                              required
                              counter
                              class="mt-2"
                              maxlength="255"
                              v-model="editedItem.title"
                              :error-messages="editedItem.$getError('title')"
                            ></v-text-field>

                            <label class="text-field-label">Description</label>
                            <v-textarea
                              solo
                              maxlength="255"
                              name="input-7-4"
                              v-model="editedItem.description"
                              :error-messages="
                                editedItem.$getError('description')
                              "
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" sm="6" md="5">
                            <label class="text-field-label">Photo</label>
                            <input v-model="editedItem.photo" hidden />
                            <div class="col-md-9">
                              <v-btn
                                @click="showCourseActivityUpload = true"
                                color="secondary"
                                class="mb-2"
                              >
                                {{ photoActivityCover ? 'Change' : 'Add' }}
                                Photo
                              </v-btn>
                              <v-img
                                size="100%"
                                class="mb-4"
                                v-if="photoActivityCover"
                                :src="photoActivityCover"
                                :lazy-src="photoActivityCover"
                              ></v-img></div
                          ></v-col>
                        </v-row>
                        <label class="text-field-label"
                          >Course Activity Items</label
                        >
                        <v-select
                          flat
                          solo
                          v-model="editedItem.course_activity_items_id"
                          :items="course_activity_items"
                          item-text="label"
                          item-value="value"
                          multiple
                          chips
                          required
                          placeholder="Select Course Activity Items"
                          persistent-hint
                          :error-messages="
                            editedItem.$getError('course_activity_items_id')
                          "
                        ></v-select>
                        <label class="text-field-label">Video</label>
                        <v-select
                          flat
                          solo
                          class="mt-2"
                          aspect-ratio="1"
                          :items="video_details"
                          item-text="label"
                          placeholder="Select Video"
                          v-model="editedItem.video_id"
                        ></v-select>
                        <label class="text-field-label">Rank</label>
                        <v-text-field
                          flat
                          solo
                          class="mt-2"
                          v-model="editedItem.rank"
                          :error-messages="editedItem.$getError('rank')"
                        ></v-text-field>
                        <v-btn
                          color="primary"
                          :loading="editedItem.$busy"
                          @click="saveActivityCourse"
                          >Save
                        </v-btn>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn color="primary" @click="finalized">
                          Submit
                        </v-btn>
                        <v-btn color="darken-1" @click="close"> Close </v-btn>
                      </v-card-actions>
                      <br />
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  {{ icons.pencil }}
                </v-icon>
                <v-icon small @click="deleteItem(item)"
                  >{{ icons.delete }}
                </v-icon>
              </template>
              <!-- <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template> -->
            </v-data-table>
          </div>
        </div>
      </v-form>
      <image-cropper v-model="showCourseUpload" @change="imageChanged" />
      <image-cropper
        v-model="showCourseActivityUpload"
        @change="imageActivityChanged"
      />
      <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
        </template>
      </v-snackbar>
      <ConfirmModal
        v-model="showDeleteModal"
        title="Delete Course"
        message="Are you sure you want to delete course?"
        @cancel="showDeleteModal = false"
        @confirm="removeCourse"
      ></ConfirmModal>
    </div>
  </div>
</template>

<script>
import Form from '@/utils/form'
import ImageCropper from '@/components/modals/ImageCropper'
import {
  mdiChevronLeft,
  mdiTrashCan,
  mdiUpdate,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { each } from 'lodash'

export default {
  name: 'CourseDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    ImageCropper,
  },

  data() {
    return {
      dialog: false,
      video_src: '',
      dialogDelete: false,
      photoActivityCover: null,
      photoCover: null,
      showCourseUpload: false,
      showCourseActivityUpload: false,
      showUpload: false,
      video_details: [],
      course_activity_items: [],
      rank: null,
      editedItem: new Form({
        title: '',
        description: '',
        duration: '',
        photo: '',
        video_id: null,
        course_activity_items_id: [],
        rank: null,
      }),
      form: null,
      showDeleteModal: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
        pencil: mdiPencil,
        delete: mdiDelete,
      },
      rules: {
        title: [(v) => (v && v.length <= 255) || 'Max 255 characters'],
      },
      activity_courses: [],
      duration_type: [
        {
          label: 'Day',
          value: 'day',
        },
      ],
      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Rank', value: 'rank', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      defaultItem: new Form({
        title: '',
        description: '',
        duration: '',
        photo: '',
        video_id: null,
        course_activity_items_id: [],
        subscriber_only: false,
        is_free: false,
        rank: null,
      }),
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      courseCategories: (state) => state.courseCategory.list,
      courseActivity: (state) => state.courseActivity.courseActivity,
      courseActivityDetails: (state) =>
        state.courseActivity.courseActivityDetails,
      courseDetails: (state) => state.course.courseDetails,
      video: (state) => state.video.list,
      exerciseItems: (state) => state.exerciseItem.list,
      activityDetails() {
        let results = []
        each(this.courseActivity, (value, key) => {
          results.push({
            title: value.title,
            rank: value.rank,
            id: value.id,
          })
        })
        return results
      },
    }),
    formTitle() {
      return this.editedItem.title
        ? 'Edit Activity Course'
        : 'Add Activity Course'
    },
  },

  mounted() {
    this.fetchCourseCategories()
    this.fetchCourseActivity(this.$route.params.id)
    this.getExerciseItemList()
    this.getVideoDetails()
  },

  methods: {
    ...mapActions({
      updateCourse: 'course/updateCourse',
      storeCourseActivity: 'courseActivity/storeCourseActivity',
      updateCourseActivity: 'courseActivity/updateCourseActivity',
      getCourseDetails: 'course/getCourseDetails',
      deleteCourse: 'course/deleteCourse',
      deleteCourseActivity: 'courseActivity/deleteCourseActivity',
      getCourseCategories: 'courseCategory/getCourseCategories',
      getCourseActivity: 'courseActivity/getCourseActivity',
      getCourseActivityDetail: 'courseActivity/getCourseActivityDetails',
      getExerciseItems: 'exerciseItem/getExerciseItems',
      getVideos: 'video/getVideos',
    }),

    ...mapMutations({
      clearCourseDetails: 'course/clearCourseDetails',
      clearCourseActivity: 'courseActivity/clearCourseActivity',
    }),
    async getVideoDetails(page = 1) {
      let params = {
        page,
      }

      await this.getVideos(params)
      each(this.video, (value, key) => {
        this.video_details.push({
          label: value.name,
          value: value.id,
        })
      })
    },

    async fetchCourseCategories(page = 1) {
      let params = { page }
      this.loading = true
      await this.getCourseCategories(params)
      this.loading = false
    },

    async fetchCourseActivity(id) {
      await this.getCourseActivity(id)
    },

    async getExerciseItemList(page = 1) {
      let params = { page }
      await this.getExerciseItems(params)
      each(this.exerciseItems, (value, key) => {
        this.course_activity_items.push({
          label: value.name,
          value: value.id,
        })
      })
    },

    async fetchCourseActivityDetails(activity) {
      await this.getCourseActivityDetail(activity)
    },

    async getCourse() {
      this.loading = true
      await this.getCourseDetails(this.$route.params.id)
      this.form = new Form(this.courseDetails)
      if (this.courseDetails.photo) {
        this.photoCover = this.courseDetails.photo.url
      }
      if (this.courseDetails.status) {
        if (this.courseDetails.status === 'enable') {
          this.form.status = true
        } else {
          this.form.status = false
        }
      }
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (this.form.photo !== null) {
        if (this.form.photo.collection_name) {
          this.form.photo = null
        }
      }

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        if (['category'].includes(key)) {
          formData.append('category_id', value.id)
        }

        if (['status'].includes(key)) {
          let result = ''
          if (value === true) {
            result = 'enable'
          } else {
            result = 'disable'
          }
          formData.append(key, result)
        } else {
          formData.append(key, value)
        }
      })
      formData.append('_method', 'PUT')

      const data = {
        data: formData,
        id: this.$route.params.id,
      }

      this.updateCourse(data)
        .then(() => {
          this.showSnackbar('Course successfully updated!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'courses',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    removeCourse() {
      this.loading = true

      this.deleteCourse(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'courses' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },

    imageChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.photoCover = reader.result
        this.form.photo = image
      }
    },

    imageActivityChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.photoActivityCover = reader.result
        this.editedItem.photo = image
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    editItem(item) {
      let activity = {
        activity_id: item.id,
        course_id: this.courseDetails.id,
      }
      this.fetchCourseActivityDetails(activity)
      setTimeout(() => {
        this.editedItem.id = this.courseActivityDetails.id
        this.editedItem.title = this.courseActivityDetails.title
        this.editedItem.description = this.courseActivityDetails.description
        this.editedItem.video_id = this.courseActivityDetails.video.id
        this.editedItem.photo = this.courseActivityDetails.photo
        this.editedItem.subscriber_only = this.subscriber_only
        this.editedItem.is_free = this.is_free
        this.editedItem.rank = this.courseActivityDetails.rank
        if (this.courseActivityDetails.items) {
          const details = this.courseActivityDetails.items
          const exerciseItemsId = details.map((exerciseItem) => exerciseItem.id)
          this.editedItem.course_activity_items_id = exerciseItemsId
        } else {
          this.editedItem.course_activity_items_id = 1
        }
        this.editedItem.duration = '00:00:00'
        if (this.courseActivityDetails.photo) {
          this.photoActivityCover = this.courseActivityDetails.photo.url
        }
      }, 700)

      this.dialog = true
    },

    deleteItem(item) {
      this.loading = true
      this.editedItemId = item.id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteCourseActivity(this.editedItemId)
        .then(() => {
          this.showSnackbar('Course successfully deleted!', 'success')
          this.fetchCourseActivity(this.courseDetails.id)
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => {
          this.loading = false
        })
      this.closeDelete()
    },

    close() {
      setTimeout(() => {
        this.editedItem.title = ''
        this.editedItem.description = ''
        this.editedItem.video = ''
        this.editedItem.photo = ''
        this.editedItem.duration = '00:00:00'
        this.photoActivityCover = ''
        this.editedItem.subscriber_only = false
        this.editedItem.is_free = false
        this.editedItem.rank = null
      }, 700)
      this.dialog = false
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = this.defaultItem
      })
    },

    finalized() {
      this.showSnackbar('Course successfully created!', 'success')
      setTimeout(() => {
        this.$router.push({
          name: 'courses',
        })
      }, 1000)
    },

    saveActivityCourse() {
      this.editedItem.$clearErrors()
      const formData = new FormData()

      each(this.editedItem.$data(), (value, key) => {
        if (['photo'].includes(key)) {
          if (value && value.collection_name) {
            formData.append(key, null)
          } else {
            formData.append(key, value)
          }
        } else if (['video'].includes(key)) {
          formData.append('video_id', this.editedItem.video_id)
        } else if (['course_activity_items_id'].includes(key)) {
          each(value, (v, k) => {
            if (v.name) {
              formData.append('course_activity_items_id[]', v.id)
            } else {
              formData.append('course_activity_items_id[]', v)
            }
          })
        } else {
          formData.append(key, value)
        }
      })

      let courseId = this.courseDetails.id
      if (this.editedItem.id) {
        courseId = this.editedItem.id
        formData.append('_method', 'PUT')
      }

      let data = {
        data: formData,
        id: courseId,
      }

      if (this.editedItem.id) {
        this.updateCourseActivity(data)
          .then(() => {
            this.fetchCourseActivity(this.courseDetails.id)
            this.editedItem = this.defaultItem
            this.clearCourseDetails()
            this.clearCourseActivity()
            this.showSnackbar(
              'Course Activity successfully updated!',
              'success'
            )
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.editedItem.$setErrors(err.response.data.errors)
            }
          })
      } else {
        this.storeCourseActivity(data)
          .then(() => {
            this.fetchCourseActivity(this.courseDetails.id)
            this.editedItem = this.defaultItem
            this.showSnackbar(
              'Course Activity successfully created!',
              'success'
            )
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.editedItem.$setErrors(err.response.data.errors)
            }
          })
      }
    },
  },

  created() {
    this.getCourse()
  },

  destroyed() {
    this.clearCourseDetails()
    this.clearCourseActivity()
  },

  watch: {
    $route() {
      this.getCourse()
    },
  },
}
</script>
